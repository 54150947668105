<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:26:23
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-11-26 16:22:09
 * @version: 
 * @Description: 公共底部
-->
<template>
  <footer class="footer">
    <div class="container">
      <div class="fcode">
        <a @click="toRoute()">联系本站 红妆公司团队 </a>
      </div>
      <p>
        © 2021 海南红妆美容管理有限公司 版权所有
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow"
          >琼ICP备12002330号-4</a
        >
      </p>
      <p>{{ poems }}</p>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: ["poetry"],
  data() {
    return {
      poems: this.poetry,
    };
  },
  watch: {
    poetry(newVal) {
      this.poems = newVal;
    },
  },
  methods: {
    toRoute() {
      this.$router.push({ name: "About" });
    },
  },
};
</script>
<style>
</style>