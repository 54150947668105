<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 18:33:51
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-15 11:49:15
 * @version: 
 * @Description: 详情页 面包导航
-->
<template>
  <div class="breadcrumbs">
    <a-skeleton
      :loading="loadingCrumbs"
      :active="loadingCrumbs"
      :paragraph="{ rows: 0 }"
    >
      <div class="container">
        当前位置：
        <a href="/">首页</a> <small>&gt;</small>
        <!--  一级栏目 -->
        <a @click="toRoute(topCid)">{{ topCategoryName }}</a
        ><small>&gt;</small>
        <!--  二级栏目 -->
        <a @click="toRoute(cid)" v-if="!single">{{ categoryName }}</a
        ><small>&gt;</small>
        正文
      </div>
    </a-skeleton>
  </div>
</template>
<script>
import { getDetail, getSingle } from "@/request/index.js";
import { Message } from "element-ui";
export default {
  name: "breadcrumbs",
  data() {
    return {
      single: false,
      categoryName: "",
      topCategoryName: "",
      cid: 0,
      topCid: 0,
      loadingCrumbs: true,
    };
  },
  watch: {
    $route: "reloadPage",
  },
  methods: {
    toRoute(id) {
      //进栏目页
      this.$router.push({ name: "Category", params: { id } });
    },
    reloadPage() {
      this.loadingCrumbs = true;
      if (this.$route.params.id) {
        getDetail(this.$route.params.id)
          .then((res) => {
            this.categoryName = res.data.categoryName;
            this.topCategoryName = res.data.topCategoryName;
            this.cid = res.data.cid;
            this.topCid = res.data.topCategoryID;
            // Message.info("栏目名称：" + this.categoryName);
            // Message.info("栏目iD：" + this.cid);
            this.loadingCrumbs = false;
          })
          .catch((err) => {
            Message.error("mounted loadingCrumbs" + err);
            this.loadingCrumbs = false;
          });
      } else {
        getSingle(15)
          .then((res) => {
            this.categoryName = res.data.categoryName;
            this.topCategoryName = res.data.topCategoryName;
            this.cid = res.data.cid;
            this.topCid = res.data.topCategoryID;
            // Message.info("栏目名称：" + this.categoryName);
            // Message.info("栏目iD：" + this.cid);
            this.loadingCrumbs = false;
          })
          .catch((err) => {
            Message.error("mounted loadingCrumbs" + err);
            this.loadingCrumbs = false;
          });
      }
    },
  },
  mounted() {
    this.loadingCrumbs = true;
    if (this.$route.params.id) {
      getDetail(this.$route.params.id)
        .then((res) => {
          this.categoryName = res.data.categoryName;
          this.topCategoryName = res.data.topCategoryName;
          this.cid = res.data.cid;
          this.topCid = res.data.topCategoryID;
          // Message.info("栏目名称：" + this.categoryName);
          // Message.info("栏目iD：" + this.cid);
          this.loadingCrumbs = false;
        })
        .catch((err) => {
          Message.error("mounted loadingCrumbs" + err);
          this.loadingCrumbs = false;
        });
    } else {
      getSingle(15)
        .then((res) => {
          this.categoryName = res.data.categoryName;
          this.topCategoryName = res.data.topCategoryName;
          this.cid = res.data.cid;
          this.topCid = res.data.topCategoryID;
          // Message.info("栏目名称：" + this.categoryName);
          // Message.info("栏目iD：" + this.cid);
          this.loadingCrumbs = false;
        })
        .catch((err) => {
          Message.error("mounted loadingCrumbs" + err);
          this.loadingCrumbs = false;
        });
    }
  },
};
</script>
<style>
</style>
