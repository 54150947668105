<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 18:09:36
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-12 10:41:52
 * @version: 
 * @Description:  搜索框
-->
<template>
  <div class="site-search" :class="{ 'search-on': isActive }">
    <div class="container">
      <form method="get" class="site-search-form">
        <input
          class="search-input"
          name="searchTxt"
          type="text"
          placeholder="输入关键字"
          value=""
          ref="searchInput"
        />
        <button class="search-btn" type="submit" @click.prevent="searchWords">
          <i class="fa fa-search"></i>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import bus from "@/bus/index.js";
// import { getSearch } from "@/request/index.js";
// import { Message } from "element-ui";
export default {
  name: "siteSearch",
  data() {
    return {
      isActive: false,
      results: [],
      count: 0,
    };
  },
  created() {
    bus.$on("openSearch", (bool) => {
      if (bool) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    });
  },
  methods: {
    searchWords() {
      console.log("提交搜索词：" + this.$refs.searchInput.value);
      let keyword = this.$refs.searchInput.value;
      //关闭搜索框
      // this.isActive = !this.isActive;

      // 将搜索词 提交给搜索结果页
      // this.$nextTick(() => {
      //   bus.$emit("keyword", keyword);
      // });

      // 清除搜索框内容;
      this.$refs.searchInput.value = "";

      // 路由切换到搜索结果页
      this.$router.push({
        name: "Search",
        query: { searchTxt: keyword },
      });
    },
  },
};
</script>
<style>
</style>