<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-12 13:41:56
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-12 14:41:47
 * @version: 
 * @Description: 遮罩层
-->

<template>
  <div class="m-mask" @click="closeMenu"></div>
</template>

<script>
import bus from "@/bus/index.js";
export default {
  name: "MaskCover",
  methods: {
    closeMenu() {
      //移除 app.vue  .m-nav-show
      console.log("关闭");
      bus.$emit("toggleMenu", false);
    },
  },
};
</script>

<style>
</style>