<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:23:56
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-17 15:20:46
 * @version: 
 * @Description: 公共头部
-->
<template>
  <header class="header">
    <div class="container">
      <h1 class="logo">
        <a href="/" title="首页">
          <img src="/logo2.png" alt="网络部" />&nbsp;</a
        >
      </h1>
      <a-skeleton
        :loading="loadingNavs"
        :active="loadingNavs"
        :paragraph="{ rows: 1 }"
      >
        <ul class="site-nav site-navbar" id="nav">
          <!-- 父栏目 -->
          <li class="menu-item">
            <a class="nav_link" @click="toRoute(0)">
              <i class="fa fa-home"></i> 首页
            </a>
          </li>
          <li
            class="menu-item"
            v-for="(topItem, index) of localTopNavs"
            :key="index"
          >
            <a
              class="nav_link"
              @click="toRoute(topItem.id, topItem.type, topItem.name)"
            >
              <i :class="topItem.icon"></i> {{ topItem.name }}
            </a>
            <!-- 子栏目 -->
            <ul
              class="sub-menu"
              v-if="getSonNavs(localSonNavs, topItem.id).length > 0"
            >
              <li
                class="menu-item"
                v-for="(sonItem, index) of getSonNavs(localSonNavs, topItem.id)"
                :key="index"
              >
                <a
                  class="nav_link"
                  @click="toRoute(sonItem.id, sonItem.type, sonItem.name)"
                >
                  <i :class="sonItem.icon"></i> {{ sonItem.name }}
                </a>
              </li>
            </ul>
          </li>
          <!-- 搜索按钮 -->
          <li class="navto-search nav_list">
            <a class="search-show active nav_link" @click="showSearchContrl">
              <i class="fa fa-search" :class="{ 'fa-remove': isActive }"></i
            ></a>
          </li>
        </ul>
      </a-skeleton>
      <i class="fa fa-bars m-icon-nav" @click="openMobileMenu"></i>
    </div>
  </header>
</template>
<script>
import bus from "@/bus/index.js";
export default {
  name: "Header",
  props: ["topNavs", "sonNavs"],
  data() {
    return {
      isActive: false,
      localTopNavs: this.topNavs,
      localSonNavs: this.sonNavs,
      loadingNavs: true,
      showMobileMenu: false,
    };
  },
  watch: {
    topNavs() {
      this.loadingNavs = true;
      this.localTopNavs = this.topNavs;
      this.loadingNavs = false;
    },
    sonNavs() {
      this.loadingNavs = true;
      this.localSonNavs = this.sonNavs;
      this.loadingNavs = false;
    },
    $route: "reloadPage",
  },
  computed: {
    getSonNavs() {
      // 传入子分类，父类ID
      return (localSonNavs, topID) => {
        //返回 子分类.pid  =  父类id 的项目
        return localSonNavs.filter((sonNav) => sonNav.pid == topID);
      };
    },
  },
  methods: {
    openMobileMenu() {
      this.showMobileMenu = true;
      console.log("this.showMobileMenu:" + this.showMobileMenu);
      bus.$emit("toggleMenu", this.showMobileMenu);
    },
    reloadPage() {
      this.isActive = false;
      bus.$emit("openSearch", this.isActive);
    },
    showSearchContrl() {
      this.isActive = !this.isActive;
      bus.$emit("openSearch", this.isActive);
    },
    toRoute(id, type, cateName) {
      if (id == 0) {
        // 0表示首页
        this.$router.push({ name: "Home", path: "/" });
      } else if (type == 1) {
        console.log(id);
        this.$router.push({ name: "Category", params: { id } });
        // this.$router.push({ path: `/category/${id}` });
        bus.$emit("getCateName", cateName);
      } else {
        //进入单页
        this.$router.push({ name: "About" });
      }
    },
  },
};
</script>
<style>
</style>