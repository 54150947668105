/*
 * @Author: ChaiHongJun
 * @Date: 2021-06-03 10:14:44
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-03 10:15:18
 * @version:
 * @Description:
 */

import Vue from 'vue'
const bus = new Vue()
export default bus