/*
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:16:54
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-25 09:00:41
 * @version: 
 * @Description: 
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;


import "./assets/css/style.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/main.css";



// import hljs from "highlight.js";
// import 'highlight.js/styles/atom-one-dark.css'


// Vue.directive('highlight', function (el) {
//   let blocks = el.querySelectorAll('pre code');
//   setTimeout(() => {
//     blocks.forEach((block) => {
//       hljs.highlightBlock(block)
//     })
//   }, 200)
// })


// import "./assets/fonts/fa-regular-400.woff2";
// import "./assets/fonts/FontAwesome.otf";
// import "./assets/fonts/fontawesome-webfont.eot";
// import "./assets/fonts/fontawesome-webfont.svg";
// import "./assets/fonts/fontawesome-webfont.ttf";
// import "./assets/fonts/fontawesome-webfont.woff";
// import "./assets/fonts/fontawesome-webfont.woff2";

import { Pagination } from "element-ui";
Vue.use(Pagination)

import { Skeleton } from "ant-design-vue";
Vue.use(Skeleton)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
