<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 15:49:47
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-12 17:20:59
 * @version: 
 * @Description: 回到顶部组件
-->
<template>
  <div class="rollbar rollbar-rb">
    <ul>
      <li>
        <a @click="toRoute(0)"><i class="fa fa-home"></i> <span>首页</span></a>
        <h6>回首页<i></i></h6>
      </li>
      <li>
        <a @click="toRoute(1)"
          ><i class="fa fa-envelope"></i> <span>联系我们</span></a
        >
        <h6>联系我们<i></i></h6>
      </li>
      <li class="rollbar-totop" style="display: list-item">
        <a href="javascript:scroll(0,0)"
          ><i class="fa fa-angle-up"></i>
          <span>回顶部</span>
        </a>
        <h6 class="top">回顶部<i></i></h6>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Rollback",
  data() {
    return {};
  },
  methods: {
    toRoute(id) {
      if (id == 0) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "About" });
      }
    },
  },
};
</script>
<style>
</style>