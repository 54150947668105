<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-12 13:40:34
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-12 17:35:47
 * @version: 
 * @Description: 移动版菜单
-->
<template>
  <ul class="m-navbar" id="m_nav">
    <li class="menu-item m_nav_list">
      <a href="/" class="m_nav_link"><i class="fa fa-home"></i>首页</a>
    </li>
    <li
      class="menu-item m_nav_list"
      v-for="(item, index) of mobileMenu"
      :key="index"
    >
      <a
        aria-current="page"
        class="m_nav_link"
        @click="toRoute(item.id, item.type)"
        ><i :class="item.icon"></i> {{ item.name }}</a
      >
    </li>
  </ul>
</template>
<script>
import bus from "@/bus/index.js";
export default {
  name: "Menu",
  props: ["topNavs"],
  computed: {
    mobileMenu() {
      return this.topNavs;
    },
  },
  methods: {
    toRoute(id, type) {
      if (type == 1) {
        this.$router.push({ name: "Category", params: { id } });
      } else if (type == 2) {
        this.$router.push({ name: "About" });
      }
      bus.$emit("toggleMenu", false);
    },
  },
};
</script>
<style>
</style>