/*
 * @Author: ChaiHongJun
 * @Date: 2021-06-09 10:09:18
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-15 09:22:24
 * @version:
 * @Description: 接口封装
 */

import api from '@/request/axios.js';


// 获取Banner
export const getBanner = () => api.get("/slides");



// 获取导航
export const getNav = (categoryID) =>
  api.get("/navigation?categoryID=" + categoryID);

// 获取资源列表
export const getArticleList = (categoryID, limit, offset) =>
  api.get("/lists?categoryID=" +
    categoryID +
    "&limit=" +
    limit +
    "&offset=" +
    offset
  );


//获取侧栏 置顶 内容列表
export const getStickyList = (categoryID, limit, offset) => api.get("/sticky?categoryID=" +
  categoryID +
  "&limit=" +
  limit +
  "&offset=" +
  offset
);


//获取侧栏 随机 内容列表
export const getRandList = (categoryID, limit, offset) => api.get("/rand?categoryID=" +
  categoryID +
  "&limit=" +
  limit +
  "&offset=" +
  offset
);



//获取内容详情
export const getDetail = (id) => api.get("/detail?id=" + id);



//文章相关内容
export const getRelatedArticles = (id) => api.get("/related?id=" + id);



//搜索功能
export const getSearch = (keyword, limit, offset) => api.get("/search?searchTxt=" + keyword + "&limit=" + limit + "&offset=" + offset);



//单页
export const getSingle = (id) => api.get("/single?cid=" + id);



// 前一篇后一篇
export const getPrevNext = (id) => api.get('prevnext?id=' + id);