/*
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:16:54
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-11 20:47:13
 * @version: 
 * @Description: 
 */
import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

// 路由重复点击
const originalReplace = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch(err => err);
};



const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/category/Index.vue"),
  },

  {
    path: "/category/:id",
    name: "Category",
    component: () => import("@/views/category/Index.vue"),
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: () => import("@/views/detail/Index.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/single/Index.vue"),
  },

  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/search/Index.vue"),
  },


];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
