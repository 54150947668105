/*
 * @Author: ChaiHongJun
 * @Date: 2021-06-09 11:23:37
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-09 16:19:59
 * @version:
 * @Description: 函数工具包
 */
// 顶级栏目
export function topNavs(objArray) {
  return objArray.filter((item) => item.pid == 0);
}


// 获取子栏目栏目
export function sonNavs(objArray) {
  return objArray.filter((item) => item.pid != 0);
}


