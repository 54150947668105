/*
 * @Author: ChaiHongJun
 * @Date: 2021-06-09 10:08:58
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-17 14:07:06
 * @version:
 * @Description:axios 封装
 *
 */
import axios from 'axios'
import { Message } from 'element-ui'
const api = {}
let apiBaseURL = "";
if (process.env.NODE_ENV === "development") {
  apiBaseURL = "https://api.hzwlb.org";  //这填写测试API
} else if (process.env.NODE_ENV === "production") {
  apiBaseURL = "https://api.hzwlb.org";     //这填写生产API
}

/* 创建axios 实例 */
let instance = axios.create({
  timeout: 10000,
  baseURL: apiBaseURL,
  // validateStatus(status) {
  //   switch (status) {
  //     case 400:
  //       Message.error('请求出错')
  //       break
  //     case 401:
  //       Message.warning({
  //         message: '授权失败，请重新登录'
  //       })
  //       // store.commit('LOGIN_OUT')
  //       setTimeout(() => {
  //         window.location.reload()
  //       }, 1000)
  //       return
  //     case 403:
  //       Message.warning({
  //         message: '拒绝访问'
  //       })
  //       break
  //     case 404:
  //       Message.warning({
  //         message: '请求错误,未找到该资源'
  //       })
  //       break
  //     case 500:
  //       Message.warning({
  //         message: '服务端错误'
  //       })
  //       break
  //   }
  //   return status >= 200 && status < 300
  // }
})
/*  全局的默认值  */
// instance.defaults.headers.post['Content-Type'] = 'application/json';



/* 拦截器 */
// 请求
instance.interceptors.request.use(
  function (config) {
    // 请求头添加token
    // if (store.state.UserToken) {
    //  config.headers.accessToken = '2332D4444594F45EE7E6370794CB4483';
    // }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
// 响应
instance.interceptors.response.use(
  response => {
    return response.data;  //返回响应头 去掉无效部分
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          Message.error('请求出错')
          break
        case 401:
          Message.warning({
            message: '授权失败，请重新登录'
          })
          // store.commit('LOGIN_OUT')
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          return
        case 403:
          Message.warning({
            message: '拒绝访问'
          })
          break
        case 404:
          Message.warning({
            message: '请求错误,未找到该资源'
          })
          break
        case 500:
          Message.warning({
            message: '服务端错误'
          })
          break
      }
    } else {
      err.message = '连接服务器失败'
    }
    return Promise.reject(err.response)
  }
)
api.get = function (url) {
  return new Promise((resolve, reject) => {
    instance
      .get(url)
      .then(response => {
        if (response.code === 200 || response.code === 204) { //这里的 response 是达到浏览器的响应
          resolve(response)   //返回响应头
        } else {
          reject(response.msg)
        }
      })
      .catch(err => {
        Message.error(err.status + "|" + err.statusText);
        console.log(err)
      })
  })
}
api.post = function (url, data) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then(response => {
        if (response.code == 200 || response.code === 204) { //这里的 response 是达到浏览器的响应
          resolve(response)
        } else {
          Message.error(response.msg);
          reject(response.msg)
        }
      })
      .catch(err => {
        Message.error(err.status + "|" + err.statusText);
        console.log(err)
      })
  })
}
export default api