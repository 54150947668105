<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:16:54
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-07-02 08:58:40
 * @version: 
 * @Description: 
-->
<template>
  <div id="app" :class="{ 'm-nav-show': isActive }">
    <blog-header :topNavs="topNavs" :sonNavs="sonNavs"></blog-header>
    <Search></Search>
    <BreadCrumbs v-if="isDetail"></BreadCrumbs>
    <router-view />
    <blog-footer :poetry="poetry"></blog-footer>
    <Rollback></Rollback>
    <Menu :topNavs="topNavs"></Menu>
    <MaskCover></MaskCover>
  </div>
</template>
<script>
import blogHeader from "@/views/common/header/Index";
import blogFooter from "@/views/common/footer/Index";
import Search from "@/components/common/search/Index";
import BreadCrumbs from "@/components/detail/breadcrumbs/Index";

import Rollback from "@/components/common/rollback/Index.vue";
import Menu from "@/components/common/menu/Index.vue";
import MaskCover from "@/components/common/mask/Index.vue";

import { getNav } from "@/request/index.js";

import { topNavs, sonNavs } from "@/utlis/index.js";
import bus from "@/bus/index.js";

export default {
  name: "App",
  data() {
    return {
      isDetail: false,
      topNavs: [],
      sonNavs: [],
      isActive: false,
      poetry: "",
    };
  },
  watch: {
    "$route.path": function (newVal) {
      if (newVal.indexOf("detail") != -1 || newVal.indexOf("about") != -1) {
        // console.log("APP 进入详情页面");
        this.isDetail = true;
      } else if (newVal.indexOf("category") != -1) {
        // console.log("APP  进入分类页面");
        this.isDetail = false;
      } else {
        // console.log("APP 进入主页面");
        this.isDetail = false;
      }
    },
  },

  components: {
    blogHeader,
    blogFooter,
    Search,
    BreadCrumbs,
    Rollback,
    Menu,
    MaskCover,
  },

  created() {
    bus.$on("toggleMenu", (data) => {
      this.isActive = data;
    });

    //加载全站导航
    getNav(0)
      .then((res) => {
        // console.log("资源：");
        // console.log(res);
        this.topNavs = topNavs(res.data); //顶级栏目
        this.sonNavs = sonNavs(res.data); //顶级栏目
        // console.log(this.topNavs);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mounted() {
    this.loadSentence();
  },

  methods: {
    loadSentence() {
      const jinrishici = require("jinrishici");
      jinrishici.load(
        (result) => {
          this.poetry = result.data.content;
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
};
</script>

